import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import NavBar from "./components/common/navbar/Navbar";
import HeroSlider from './components/common/slider/Slider';
import About from "./components/about/About";
import Waveworks from "./components/waveworks/Waveworks";
import Product from "./components/product/Product";
import Sectors from "./components/sectors/Sectors";
import Contacts from "./components/contacts/Contacts";
import Footer from "./components/common/footer/Footer";
// import Login from "./components/login/Login";



const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={
            <>
              <NavBar />
              <HeroSlider />
              <Waveworks />
              <Product />
              <About />
              <Sectors />
              <Contacts />
              <Footer />
            </>
          }
          />
          {/* <Route path='/login' element={<Login />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;