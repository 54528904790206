import './contacts.scss';
import { webData } from '../constants/data'

const Contacts = () => {
	return (
		<>
			<section className="contacts" id='contact'>
				<div className="breadcrumbs">
					<h2>{webData.contactHeading}</h2>
				</div>
				<div className='mapping-part'>
					<div className="container">
						<div className="inner">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-12">
									<div id="Map">
										<div>
											<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.039755753302!2d77.3766230745678!3d28.508451589706127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce88244122b8b%3A0x755392d190c5e334!2sDwellSMART%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1713352947502!5m2!1sen!2sin"
												style={{ "width": "100%", "height": 378, border: "0" }}
												allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-12">
									<div className="connect">
										<h2>{webData.contactData}</h2>
										<p>{webData.content}</p>
										<div className='support'>
											<h3>{webData.support}</h3>
											<i className="fa fa-envelope" aria-hidden="true"></i>
											<a href="mailto:sales@dwellsmart.co.in">
												{webData.email}
												</a>
										</div>
										<div className='support'>
											<h3>{webData.timing}</h3>
											<div>{webData.openTime}</div>
											<h3>{webData.closeDay}</h3>
											<div>{webData.close}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
		</>
	);
};

export default Contacts;
