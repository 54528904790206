import React, { useState } from 'react';
import './tabs.scss'
import TabsArct from './TabsArct';
import TabsWorks from './TabsWorks';


const Tabs = ({ tabData }) => {
    const [tab, setTab] = useState(tabData.key1);
    const handleTab = (key) => {
        setTab(key);
    };

    
    return (
        <>
  {/* tabbing section  start here*/}
                <div className='tabbing-cover'>
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className='tabs'>
                            <div className={`${tab === tabData.key1 ? 'tab active' : 'tabList'}`} onClick={()=>handleTab(tabData.key1)}>
                                    {tabData.tabHead1}
                                </div>
                            <div className={`${tab === tabData.key2 ? 'tab active' : 'tab'}`} onClick={()=>handleTab(tabData.key2)}>
                                    {tabData.tabHead2}
                                </div>
                                
                                
                              
                            </div>
                            {
                                tab === 'architecture' && (
                                    <>
                                        <TabsArct/>
                                        </>
                                )}
                                        {tab === 'works' && (
                                            <>
                                                <TabsWorks />
                                            </>
                                        )}
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Tabs;