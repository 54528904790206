import { webData } from '../constants/data';
import './product.scss';
import threePhaseMeter from '../../assets/images/three_phase.png';
import singlePhaseMeter from '../../assets/images/single_phase.png';
import dcu from '../../assets/images/dcu.png';
import dgInjector from '../../assets/images/dg.png'
import hmi from '../../assets/images/hmi.png';
import { Link } from 'react-router-dom';


const Product = () => {
	return (
		<>
			<section className="product section" id='product'>
				<div className='meters'>
					<div className="container">
						{/* common section */}
						<div className="row ">
							<div className="col-lg-12">
								<div className="section-title">
									<h2>{webData.meterHeading}</h2>
									<img src={`images/${webData.commonImg}.png`} alt="section image" />
									<p>{webData.meterContent}
									</p>
								</div>
							</div>
						</div>

						{/* meters specifications start here */}
						<div className="row">
							<div className='col-lg-4 col-md-12 col-12'>
								<div className='product-item meteruse'>
									<img src={singlePhaseMeter} alt="meter img" />
									<h3>Single Phase Prepaid Meter</h3>
									<p>Single Phase Dual Source Meter ensures precise measurement of energy consumption, providing reliable data for both utility companies and consumers, enhancing transparency and promoting energy efficiency initiatives.</p>
									<div className='readmore'>Read more...</div>
									<div className="animation">
										<div className='meter-about'>
											<h6> Technical specifications</h6>
											<div className="row">
												<div className="col-lg-12 col-md-12">
													<div className='meter-details'>
														<div className='row'>
															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>
																		<strong>Rated Voltage: </strong>
																	</li>
																	<li>
																		<strong>Current Rating:</strong>
																	</li>
																	<li>
																		<strong>Frequency:</strong>
																	</li>
																	<li>
																		<strong>Communication:</strong>
																	</li>
																	<li>
																		<strong>Relay:</strong>
																	</li>
																	<li>
																		<strong>DG Sensing:</strong>
																	</li>
																	<li>
																		<strong>Accuracy:</strong>
																	</li>
																	<li>
																		<strong>Starting Current:</strong>
																	</li>


																	<li>
																		<strong>LED Indicator:</strong>
																	</li>

																	<li>
																		<strong>Connection:</strong>
																	</li>

																	<li>
																		<strong>Operating Temp:</strong>
																	</li>
																	<li>
																		<strong>LCD Indicator:</strong>
																	</li>

																</ul>
															</div>
															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>240V</li>
																	<li>10-60A</li>
																	<li>50Hz ± 5%</li>
																	<li>RS485</li>
																	<li>Yes</li>
																	<li>240V AC signal</li>
																	<li>Class1 as per IS13779</li>
																	<li>0.2% of basic current</li>
																	<li>Pulse LEDs for kWh</li>
																	<li>1 Phase 2 Wire</li>
																	<li>-100°C to +500°C</li>
																	<li>Dual Line 8digit backlit display</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-4 col-md-12 col-12'>
								<div className='product-item meteruse'>
									<img src={threePhaseMeter} alt="meter img" />
									<h3>Three Phase Prepaid Meter</h3>
									<p>Reliable and accurate three-phase meters ensure indisputable energy consumption data, greatly fostering trust among utility stakeholders and consumers alike, bolstering transparency and accountability.</p>
									<div className='readmore'>Read more...</div>

									<div className="animation">
										<div className='meter-about'>
											<h6> Technical specifications</h6>
											<div className="row">
												<div className="col-lg-12 col-md-12">
													<div className='meter-details'>
														<div className='row'>

															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>
																		<strong>Rated Voltage: </strong>
																	</li>
																	<li>
																		<strong>Current Rating:</strong>
																	</li>
																	<li>
																		<strong>Frequency:</strong>
																	</li>
																	<li>
																		<strong>Communication:</strong>
																	</li>

																	<li>
																		<strong>Relay:</strong>
																	</li>
																	<li>
																		<strong>DG Sensing:</strong>
																	</li>
																	<li>
																		<strong>Accuracy:</strong>
																	</li>
																	<li>
																		<strong>Starting Current:</strong>
																	</li>

																	<li>
																		<strong>LED Indicator:</strong>
																	</li>

																	<li>
																		<strong>Connection:</strong>
																	</li>
																	<li>
																		<strong>Operating Temp:</strong>
																	</li>

																	<li>
																		<strong>LCD Indicator:</strong>
																	</li>


																</ul>
															</div>
															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>240V</li>
																	<li>10-60A</li>
																	<li>50Hz ± 5%</li>
																	<li>RS485</li>
																	<li>Yes</li>
																	<li>240V AC signal</li>
																	<li>Class1 as per IS13779</li>
																	<li>0.2% of basic current</li>
																	<li>Pulse LEDs for kWh</li>
																	<li>3 Phase 4 Wire</li>
																	<li>-100°C to +500°C</li>
																	<li >Dual Line 8 digit backlit display</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-4 col-md-12 col-12'>
								<div className='product-item meteruse'>
									<img src={threePhaseMeter} alt="meter img" />
									<h3>Three Phase Prepaid Meter</h3>
									<h6>(with Gas & Water Meter Integration)</h6>
									<p>Introducing our innovative Three Phase Prepaid Meter with Gas & Water Meter Integration, revolutionizing utility management for residential and commercial properties.</p>
									<div className='readmore'>Read more...</div>
									<div className="animation">
										<div className='meter-about'>
											<h6> Technical specifications</h6>
											<div className="row">
												<div className="col-lg-12 col-md-12">
													<div className='meter-details'>
														<div className='row'>

															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>
																		<strong>Rated Voltage: </strong>
																	</li>
																	<li>
																		<strong>Current Rating:</strong>
																	</li>
																	<li>
																		<strong>Frequency:</strong>
																	</li>
																	<li>
																		<strong>Communication:</strong>
																	</li>

																	<li>
																		<strong>Relay:</strong>
																	</li>
																	<li>
																		<strong>DG Sensing:</strong>
																	</li>
																	<li>
																		<strong>Accuracy:</strong>
																	</li>
																	<li>
																		<strong>Starting Current:</strong>
																	</li>

																	<li>
																		<strong>LED Indicator:</strong>
																	</li>

																	<li>
																		<strong>Connection:</strong>
																	</li>
																	<li>
																		<strong>Operating Temp:</strong>
																	</li>
																	<li>
																		<strong>LCD Indicator:</strong>
																	</li>
																	<br />
																	<li>
																		<strong>DI Inputs:</strong>
																	</li>

																</ul>
															</div>
															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>240V</li>
																	<li>10-60A</li>
																	<li>50Hz ± 5%</li>
																	<li>RS485</li>
																	<li>Yes</li>
																	<li>240V AC signal</li>
																	<li>Class1 as per IS13779</li>
																	<li>0.2% of basic current</li>
																	<li>Pulse LEDs for kWh</li>
																	<li>3 Phase 4 Wire</li>
																	<li>-100°C to +500°C</li>
																	<li>Dual Line 8digit backlit display</li>
																	<li>2 Channels for Gas/Water meter Integration </li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/*  Data gateway & DG injector section */}
						<div className="row">
							<div className='col-lg-4 col-md-12 col-12'>
								<div className='product-item productuse'>
									<img src={dcu} alt="dcu img" />
									<h3>DATA GATEWAY</h3>
									<p>Our Data Gateway seamlessly links Digital Energy Meters  ensuring reliable communication and efficient data management, optimizing your energy monitoring system.</p>

									<div className='readmore'>Read more...</div>
									<div className="animation">
										<div className='meter-about'>
											<h6> Technical specifications</h6>
											<div className="row">
												<div className="col-lg-12 col-md-12">
													<div className='meter-details'>
														<div className='row'>

															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>
																		<strong>Input: </strong>
																	</li>
																	<li>
																		<strong>Mount:</strong>
																	</li>
																	<li> <strong>Serial:</strong> </li>
																	<li> <strong>Baudrate:</strong> </li>
																	<li> <strong>Operating Temp:</strong> </li>
																	<li> <strong>Ethernet:</strong> </li>
																</ul>
															</div>
															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>9V-24V DC</li>
																	<li>Di N Rail</li>
																	<li>RS 485 </li>
																	<li>300 ~ 11520 bps </li>
																	<li>-40°c to 50°c</li>
																	<li>10/100M auto negotiation RJ45 connector </li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-4 col-md-12 col-12'>
								<div className='product-item productuse'>
									<img src={dgInjector} alt="meter img" />
									<h3>DG Injector</h3>
									<p>Single Phase Dual Source Meter ensures precise measurement of energy consumption, providing reliable data for both utility companies and consumers</p>
									<div className='readmore'>Read more...</div>
									<div className="animation">
										<div className='meter-about'>
											<h6> Technical specifications</h6>
											<div className="row">
												<div className="col-lg-12 col-md-12">
													<div className='meter-details'>
														<div className='row'>

															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>
																		<strong>Input: </strong>
																	</li>

																	<li>
																		<strong>DG Sensing:</strong>
																	</li>
																	<li>
																		<strong>Operating Temp:</strong>
																	</li>
																	<li>
																		<strong>Ethernet:</strong>
																	</li>
																</ul>
															</div>
															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>5V C-type 2 AMP.</li>
																	<li>220V AC </li>
																	<li>-40°C to +55°C</li>
																	<li>10/100M RJ45 connector</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-4 col-md-12 col-12'>
								<div className='product-item productuse'>
									<img src={hmi} alt="hmi img" />
									<h3>HOME DISPLAY UNIT</h3>
									<p> Home Display Unit seamlessly complements your Prepaid Meter, providing convenient energy monitoring with essential features. Stay informed about your energy usage of the your home</p>
									<div className='readmore'>Read more...</div>
									<div className="animation">
										<div className='meter-about'>
											<h6> Technical specifications</h6>
											<div className="row">
												<div className="col-lg-12 col-md-12">
													<div className='meter-details'>
														<div className='row'>
															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>
																		<strong>Input: </strong>
																	</li>
																	<li>
																		<strong>Communication:</strong>
																	</li>
																	<li>
																		<strong>Connector:</strong>
																	</li>
																	<li>
																		<strong>Scroll:</strong>
																	</li>
																	<li>
																		<strong>LED Indicators:</strong>
																	</li>
																	<li>
																		<strong>Buzzer:</strong>
																	</li>
																</ul>
															</div>
															<div className='col-lg-6 col-md-6 col-6'>
																<ul>
																	<li>+5V DC  </li>
																	<li>RS 485 modbus</li>
																	<li>4 Wire screw terminal</li>
																	<li>Left & Right keys</li>
																	<li>DG and overload</li>
																	<li>15 sec. for DG</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* mobile app section start here */}
				<div className="mobile-app">
					<div className="container">
						{/* common row */}
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title">
									<h2>{webData.appHeading}</h2>
									<img src={`images/${webData.commonImg}.png`} alt="section image" />
									<p>{webData.appContent}</p>
								</div>
							</div>
						</div>

						<div className="row">
							{
								webData.appDetails.map((item, index) => {
									return <div className="single-mobile" key={index}>
										<div className="mobile-head">
											<img src={`images/${item.image}.png`} alt="mobile image" />
										</div>
										<div className="mobile-body">
											<div className="mobile-content">
												<h3>{item.deviceFeature}</h3>
												<p>{item.deviceSpecification}</p>
												<h3>{item.realTime}</h3>
												<p>{item.realTimeSpecification}</p>
												<div className='scanner'>
													<img src={`images/${item.scanner}.png`} alt="scanner image" />

													<div className='store_icon'>
														<Link to="https://apps.apple.com/in/app/waveplus/id6473162557" target="_blank">
														<img src="images/app_store.svg" alt='apple store'/>
														</Link>
														<Link to="https://play.google.com/store/apps/details?id=com.dwellsmart" target="_blank">
															<img src="images/play_store.svg" alt="Play Store" />
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								})
							},
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default Product;