import './waveworks.scss';
import { webData } from '../constants/data'
import Tabs from '../common/tabbing/Tabs';
import archDesktop from '../../assets/images/architecture-desk.png';
import archMob from '../../assets/images/architecture-mob.png';


const Waveworks = () => {
    const { tabData1 } = webData;
    return (
        <>
            <section className="wave-cover section" id='wavework'>
                <div className="container">
                    {/* common section start */}
                    <div className='common-content'>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2>{webData.wavePlusWoksHead}</h2>
                                    <img src={`images/${webData.commonImg}.png`} alt="section image" />
                                    <p>{webData.wavePlusWoksData}</p>
                                </div>
                            </div>
                        </div>
                    </div>
   {/*  architecture section*/}
                    <div className='wave-architecture'>
                        <picture>
                            <source media="(max-width:767px)" srcSet={archMob} />
                            <img src={archDesktop} alt="slider images" />
                        </picture>
                    </div>
                    {/* tabbing part-I */}
                    <Tabs tabData={tabData1} />
                    
                </div>
            </section>
        </>
    )
}

export default Waveworks;