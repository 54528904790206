import React from 'react'
import { webData } from '../../constants/data'

const TabsArct = () => {
  return (
    <>
      <div className='tab-data'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2>{webData.architectureHead}</h2>
            <div className='tab-details'>
              {/* Key Features section start */}
              <div className='feacture-keys'>
                <div className="container">
                  <div className="row">
                    {
                      webData.feactureKeys1.map((item, index) => {
                        return <div className="col-lg-4 col-md-4 feactures" key={index}>
                          <img src={`images/${item.img}.png`} alt='icon image' />
                          <div className='feacture-content'>
                            <h2>{item.keyHeading}</h2>
                            <p>{item.para}</p>
                          </div>
                        </div>
                      })
                    }
                  </div>
                  <div className="row">
                    {
                      webData.feactureKeys2.map((item, index) => {
                        return <div className="col-lg-4 col-md-4 col-12 feactures" key={index}>
                          <img src={`images/${item.img}.png`} alt='icon image' />
                          <div className='feacture-content'>
                            <h2>{item.keyHeading}</h2>
                            <p>{item.para}</p>
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TabsArct