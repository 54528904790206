import './footer.scss';
import { webData } from '../../constants/data';
import React, { useState } from 'react';
import { Link } from "react-router-dom";


const Footer = () => {
	const handleScroll = (id) => {
		const element = document.getElementById(id);
		element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
	}

	return (
		<>
			<footer className="footer ">
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-12">
								<div className="single-footer">
									<h2>{webData.pageTitle}</h2>
									<p>{webData.description}</p>
									<ul className="social">
										{
											webData.socialIcons.map((item, index) => {
												return <li key={index}>
													<Link to={`${item.url}`} target="_blank">
														<i className={`fa fa-${item.icon}`}></i>
														</Link>
														</li>
											})
										}
									</ul>
								</div>
							</div>

							<div className="col-lg-4 col-md-4 col-12">
								<div className="single-footer">
									<h2>{webData.quickLinks}</h2>
									<div className="row">
										<div className="col-lg-12 col-md-12 col-12">
											<ul className='footer-link'>
												{
													webData.links1.map((item, index) => {
														return (
															<li key={index} onClick={() => handleScroll(item.id)}>
																<Link to="/">{item.text}</Link>
															</li>
														)
													})
												}
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-12">
								<div className="single-footer">
									<h2>{webData.address}</h2>
									<ul>
										<li>
											<h5>{webData.headoffice}</h5>
											<p>{webData.headofficeAddress}</p>
										</li>
										<li>
											<h5>{webData.corporate}</h5>
											<p>{webData.corporateAddress}</p>
										</li>
									</ul>

									<h5>{webData.WorkLocation}</h5>
									<p>{webData.para}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copyright">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-12">
								<p>{webData.copy} <strong>DwellSMART Pvt. Ltd.</strong></p>
							</div>
						</div>
					</div>
				</div>
			</footer>

		</>
	);
};
export default Footer;
