import './about.scss';
import { webData } from '../constants/data';
import aboutMe from '../../assets/images/aboutme.png';



const About = () => {
	return (
		<>
			<section className="about section" id='about'>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">
								<h2>{webData.aboutHeading}</h2>
								<img src={`images/${webData.commonImg}.png`} alt="section image" />
								<p>{webData.aboutContent}</p>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-6 col-md-12 col-12">
							<div className="about-left">
								<h3>{webData.aboutMe}</h3>
								<p>{webData.aboutMedetail1}</p>
								<p>{webData.aboutMedetail2}</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-12">
							<div className="about-right">
								<img src={aboutMe} alt='about image' />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default About;