import OwlCarousel from 'react-owl-carousel2';
import { webData } from '../../constants/data';
import './slider.scss';

const HeroSlider = () => {
	const options = {
		items: 1,
		nav: true,
		rewind: true,
		autoplay: true,
		dots: false,
	};
	return (
		<>
			<section className="slider">
				<OwlCarousel options={options}>
					{
						webData?.sliderList.map((item, index) => {
							return (
								<div className="single-slice" key={index}>
									<picture>
									<source media="(max-width:767px)" srcSet={`/images/${item.mobileImage}.png`}/>
									<img src={`/images/${item.image}.png`} alt="slider images"/>
									</picture>
								</div>
							)
						})
					}
				</OwlCarousel>
			</section>
		</>
	);
}
export default HeroSlider;
