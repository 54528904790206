import React from 'react';
import { webData } from '../../constants/data';


const TabsWorks = () => {
  return (
    <>
      <div className='tab-data'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2>{webData.keyHead}</h2>
            <div className='tab-details'>
{
                webData.aboutKeys.map((item, index) => (
                    <div key={index}>
                        <strong>{item.keyName}</strong>
                        <p>{item.keyData}</p>
                    </div>
                ))
            }
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default TabsWorks